<template>
  <div class="container d-flex h-100">
    <div class="w-100 justify-content-center align-self-center text-center">
      <select-domestic-registration v-if="currentSlide === 'domestic_registration'" @next="setCurrentSlide" />
      <select-company-formed
        v-else-if="currentSlide === 'formation_info'"
        @next="setCurrentSlide"
      />
      <select-company-starting-point v-else-if="currentSlide === 'select_starting_point'" />
      <select-stage v-else-if="currentSlide === 'select_stage'" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'DecisionTreePage',
    components: {
      SelectDomesticRegistration: () => import('@/components/StagelineV2/decisionTree/SelectDomesticRegistration.vue'),
      SelectCompanyFormed:        () => import('@/components/StagelineV2/decisionTree/SelectCompanyFormed.vue'),
      SelectCompanyStartingPoint: () => import('@/components/StagelineV2/decisionTree/SelectCompanyStartingPoint.vue'),
      SelectStage:                () => import('@/components/StagelineV2/decisionTree/SelectStage.vue'),
    },
    data() {
      return {
        continueDecisionTree: false,
        currentSlide: null,
      }
    },
    computed: {
      ...mapGetters('stageline', [
        'company',
        'stagelineHasBeenVisited',
        'startEntityTypeSupported',
      ]),
      ...mapGetters('companies', [
        'formedElsewhere',
        'formationInfoObtained',
        'formationDateMissing',
        'alreadyFormed',
      ]),
      showCompanyFormationDate() {
        return this.formedElsewhere && this.formationDateMissing
      },
      showCompanyStartingPoint() {
        return !this.stagelineHasBeenVisited && !this.formedElsewhere && !this.alreadyFormed
      },
    },
    async mounted() {
      const params    = this.$route.params
      const companyId = params.companyId
      this.setCurrentSlide()

      if (companyId && (!this.company || params.redirect)) {
        // todo maybe here
        await this.setCurrentCompany({ id: companyId })
      }
    },
    methods: {
      ...mapActions('companies', [
        'setCurrentCompany',
      ]),
      setCurrentSlide() {
        if (this.company && !this.company?.domestic_registration) {
          this.currentSlide = 'domestic_registration'
        } else if (!this.formationInfoObtained || this.showCompanyFormationDate) {
          this.currentSlide = 'formation_info'
        } else if (this.startEntityTypeSupported && this.showCompanyStartingPoint) {
          this.currentSlide = 'select_starting_point'
        } else {
          this.currentSlide = 'select_stage'
        }
      },
    },
  }
</script>
